<template>
  <div>
    <h2>Our Story</h2>
    <timeline
      class="timeline"
      :timeline="timeline"
    />
  </div>
</template>

<script>
import Timeline from '@/components/Timeline/index.vue';
import timeline from '@/data/timeline.json';

export default {
  name: 'OurStoryPage',
  components: {
    Timeline,
  },
  data: () => ({
    timeline,
  }),
};
</script>

<style lang="scss" scoped>
.timeline {
  margin-left: 5px;
  padding-bottom: calc(100vh - 70px - 99px);

  @media (min-width: $timeline-mobile-cutoff) {
    padding-bottom: calc(100vh - 65px - 60px);
  }
}
</style>
